const { h, uniqueTag, Component, define, apis, createRef, render } = omii;
import css from "./css/otherLogin.scss";
import { constant } from './lib/constant'
export default class extends Component{
  static css = css;
  activeValue = -1;

  toWXLogin(){
    console.log('wx')
    window.location.href="https://open.weixin.qq.com/connect/qrconnect?appid="+constant.weixinId+"&redirect_uri="+encodeURIComponent('https://personalcenter.afdata.org.cn')+"&response_type=code&scope=snsapi_login&state=weixin#wechat_redirect"
  }
  toQQLogin(){
    console.log('qq')
    window.location.href="https://graph.qq.com/oauth2.0/authorize?response_type=code&client_id="+constant.QQappid+"&redirect_uri=https://personalcenter.afdata.org.cn&state=1213456"
  }
  toWeiboLogin(){
    console.log('weibo')
    window.location.href="https://api.weibo.com/oauth2/authorize?client_id="+constant.weiboKey+"&response_type=code&redirect_uri=https://personalcenter.afdata.org.cn"
  }
  changeActive(value){
    this.activeValue=value
    this.update();
  }
  render(){
    return(
      <div className="otherLogin">
        <div className="title"><span>其他登录方式</span></div>
        <div className="image">
          <div className="loginIcon"  onMouseOver={evt=>{this.changeActive(0)}} onMouseLeave={evt=>{this.changeActive(-1)}}>
            <text className={this.activeValue==0?'active':''}>QQ登录</text>
            <img src='../assets/images/otherLogin/qq1.png' onClick={evt=>{ this.toQQLogin() }}/>
          </div>
          <div className="loginIcon" onMouseOver={evt=>{this.changeActive(1)}} onMouseLeave={evt=>{this.changeActive(-1)}}>
            <text className={this.activeValue==1?'active':''}>微信登录</text>
            <img src='../assets/images/otherLogin/wechat.png' onClick={evt=>{ this.toWXLogin() }}/>
          </div>
          <div className="loginIcon" onMouseOver={evt=>{this.changeActive(2)}} onMouseLeave={evt=>{this.changeActive(-1)}}>
            <text className={this.activeValue==2?'active':''}>微博登录</text>
            <img src='../assets/images/otherLogin/weibo.png' onClick={evt=>{ this.toWeiboLogin() }}/>
          </div>
        </div>
      </div>
    )
  }
}
