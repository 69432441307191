const { h, uniqueTag, Component, define, route, classNames, createRef } = omii;
import security from "./security";
import css from "./css/home.scss";

import side from "./layout/side";
import Cookies from 'js-cookie'


import collect from "./components/collect"
import focus from "./components/focus";
import infoCenter from "./components/infocenter";
import message from "./components/message";
import board from "./components/board";
import remarks from "./components/remarks";
import profile from "./components/profile";
import expert from "./components/expertApply";
import activity from "./components/activity";
import demand from "./components/demand";
import { getToken, removeToken } from "./token";


define("af-side-menu", side);
define("af-profile", profile);
define("af-focus", focus);
define("af-message", message);
define("af-board", board);
define("af-remarks", remarks);
define("af-collect", collect);
define("af-info-center", infoCenter);
define("af-apply", expert);
define("af-activity", activity);
define("af-demand",demand)

// let userInfo = await security.getUserInfo();

// if (userInfo.code != 200) {
//   security.logout();
// }
const Pages = [{
  key: "profile",
  name: "我的资料",
  content: <af-profile />
},
{
  key: "collect",
  icon: "star",
  name: "我的关注",
  content: <af-collect />
},
{
  key: "message",
  name: "我的订阅",
  content: <af-message />
},
{
  key: "focus",
  name: "我的收藏",
  content: <af-focus />
},
{
  key: "activity",
  name: "我的报名",
  content: <af-activity />
},
{
  key: "remarks",
  name: "我的留言",
  content: <af-remarks />
},
{
  key: "board",
  name: "洽谈邀约",
  content: <af-board />
},
{
  key: "infoCenter",
  name: "消息中心",
  content: <af-info-center />
},
// {
//   key: "demand",
//   name: "发布需求",
//   content: <af-demand/>
// },
  // ,{
  //   key: "applyExpert",
  //   name: "专家申请",
  //   content: <af-apply />
  // }
  // },
  // {
  //   // key: "infoCenter",
  //   name: "企业服务中心",
  //   // content: <af-info-center />
  // },

];

const App = uniqueTag(
  class extends Component {
    static css = css;
    score = 0;
    userInfo = {};
    entInfo = {};
    authentication = "企业认证"
    activePageKey = "profile";
    authPageKey = "auth";
    flag = false
    enterpriseList = []
    username = "";
    whiteUrl = ['https://award.afdata.org.cn/','http://localhost:8082/'];//可直接跳入的白名单地址（外部链接）

    imgRef = createRef();
    openPages = [{
      key: "profile",
      name: "我的资料",
      content: <af-profile />
    }];
    openPages = [{
      key: "profile",
      name: "我的资料",
      content: <af-profile />
    }];
    logout() {
      security.logout();
      location.reload();
      let obj = {};
      for (let i = 0; i < paramArr.length; i++) {
        let name = paramArr[i].substr(0, paramArr[i].indexOf("="));
        let value = paramArr[i].substr(paramArr[i].indexOf("=") + 1);
        if (name) {
          obj[name] = value;
        }
      }
      if (obj.redirectUrl) {
        sessionStorage.setItem("centerRedirect", obj.redirectUrl);
        location.href = "/?redirectUrl=" + obj.redirectUrl;
      } else {
        location.href = "/";
      }
    }
    // userInfo() {
    //   fetch("/prod-api/system/user/getInfoNew", {
    //     method: "get",
    //     headers: {
    //       Authorization: `Bearer ${getToken()}`
    //     }
    //   }).then(response => response.json())
    //     .then(res => {
    //       this.username = res.user.userName
    //       console.log(this.username)
    //       this.update();
    //     })
    // }
    getList(num) {
      // let pageNum = e.detail;
      // let obj = {
      //   deFlag:'0'
      // }
      fetch("/prod-api/manage/enterprise/entHistory/list?deFlag=0&pageSize=1000", {
        method: "get",
        headers: {
          "Content-type": "application/json",
          "SiteKey": "Portal",
          Authorization: `Bearer ${getToken()}`
        }
      }).then(response => response.json())
        .then(res => {
          // console.log(res)
          if (res.code == 200) {
            this.enterpriseList = res.rows
            //  let flag = this.enterpriseList.filter(item=>item==this.username&& item.auditStatus == '3')
            //  console.log(flag);
          }

          // if (res.code == 200 & res.data & res.data.data) {
          //     this.dataList = res.data.data.records;
          //     this.data.total = res.data.data.total;
          // } else {
          //     this.data.total = 0;
          // }
          this.update();
        })
      // this.data.pageNum = pageNum;
    }
    async install() {
      // this.userInfo()
      // this.getList()
      // console.log(location.href)
      if (location.hash) {
        this.activePageKey = location.hash.substring(2, location.hash.indexOf("?"));
      }
      // this.userInfo = JSON.parse(sessionStorage.getItem("expertUser"));
      // if (this.userInfo == undefined || this.userInfo == null) {
        this.userInfo = await security.getUserInfo();
      // }
      //  let flag = this.enterpriseList.filter(item=>item.entName==this.username&& item.auditStatus == '3')
      //  console.log(flag);
      // console.log(this.username);
      if (this.userInfo.code != 200) {
        Cookies.remove("Ent-Token");
        Cookies.remove("Expert-Token");
        this.logout();
      } else {
        sessionStorage.setItem("expertUser", JSON.stringify(this.userInfo))
        this.username = this.userInfo.user.userName
      }

      this.entInfo = await security.getEntUserInfo();
      let flag = this.enterpriseList.filter(item => item.entName == this.entInfo.data.entName && item.auditStatus == '3')
      // if (flag.length) {
      //   this.flag = true
      // }
      if (this.userInfo.user) {
        if (this.userInfo.user.userType == "01" || this.userInfo.user.userType == "03") {
          this.flag = true;
          location.href = serviceCenterUrl;
        }
      }

      if (this.entInfo.data) {
        this.authentication = ''
        // this.update()
      }
      // console.log(this.entInfo);
      Pages.forEach((page) => {
        route(`/${page.key}`, (evt) => {
          this.authPageKey = page.key;
          this.updateSelf();
        });
      });
      route("*", () => {
        console.log("not found");
      });

    }
    // 跳转到企业认证
    goAutEnt() {
      location.href = "#/Auth";
    }
    signDay() {
      fetch("/prod-api/manage/score/add?type=1&score=5&typeName=每日签到", {
        method: 'get',
        headers: {
          Authorization: `Bearer ${getToken()}`
        }
      }).then(response => response.json())
        .then(data => {
          if (data.code == 200) {
            alert("签到成功")
          } else {
            alert(data.msg)
          }
        })
    }
    installed() {
      // console.log(this.entInfo);
      let { data } = this.entInfo;
      // console.log(data);
      if (!data) {
        this.$(".entLi").parentNode.removeChild(this.$(".entLi"));
      }
    }
    render() {
      let { user, roles, permissions } = this.userInfo;
      // if(user?.userCategory.endsWith("1")){
      //   alert("非法账号");
      //   removeToken();
      //   location.href="/";
      // }
      if (location.hash) {
        if (location.hash.indexOf("?") >= 0) {
          this.activePageKey = location.hash.substr(2, location.hash.indexOf("?") - 2);
        } else {
          this.activePageKey = location.hash.substr(2)
        }
        // console.log(this.activePageKey)
      }
      var paramArr = location.href.substr(location.href.indexOf("?") + 1).split("&");
      let obj = {};
      for (let i = 0; i < paramArr.length; i++) {
        let name = paramArr[i].substr(0, paramArr[i].indexOf("="));
        let value = paramArr[i].substr(paramArr[i].indexOf("=") + 1);
        if (name) {
          obj[name] = value;
        }
      }
      // console.log(obj)
      if (obj.redirectUrl) {
        sessionStorage.setItem("centerRedirect", obj.redirectUrl);
      }
      if (security.isLogined) {
         //url白名单中的地址直接跳入 --add by sxm 20230424
          let isWhite = false;
          var rUrl = "";
          if (sessionStorage.getItem("centerRedirect") && sessionStorage.getItem("centerRedirect") != null) {
              rUrl = sessionStorage.getItem("centerRedirect");
              sessionStorage.removeItem("centerRedirect");
              this.whiteUrl.forEach(wUrl=>{
                  if(rUrl==wUrl){
                      isWhite = true;
                  }
              })
          }
          if(isWhite && rUrl){//重定向地址为白名单中地址，直接跳入
              if (Cookies.get("Ent-Token")) {
                  if (rUrl.indexOf("?") >= 0) {
                      location.href = rUrl + "&token=" + getToken();
                  } else {
                      location.href = rUrl + "?token=" + getToken();
                  }
              } else {
                  location.href = rUrl;
              }
          }else{
              if (user.userType == "01") {//企业跳到企业服务中心
                  if (rUrl!="") {
                      // if(rUrl=="https://enterprisecenter.afdata.org.cn/surveyCate/survey"){
                      if(rUrl.indexOf("surveyCate/survey")>-1 || rUrl.indexOf("members/yearCheck")>-1 || rUrl.indexOf("exh/innovate")>-1 || rUrl.indexOf("memberApply/form")>-1){//该链接需直接进入(统计调查、会员年审、创新产品申报、申请会员)--add by sxm 20240304
                          location.href = rUrl;
                      }else {
                          location.href = entCenterUrl;
                      }
                  } else {
                      location.href = entCenterUrl;
                  }
              } else {
                  if (rUrl!="") {
                      if (Cookies.get("Ent-Token")) {
                          if (rUrl.indexOf("?") >= 0) {
                              location.href = rUrl + "&token=" + getToken();
                          } else {
                              location.href = rUrl + "?token=" + getToken();
                          }
                      } else {
                          location.href = rUrl;
                      }
                  }
              }
          }
      }
      // console.log(user)
      console.log(this.activePageKey);
      // let $contentsPage = Pages.find(page => page.key == this.activePageKey)
      // console.log(this.activePageKey);
      let $contentsPage = Pages.find(page => page.key == this.activePageKey);
      // console.log($contentsPage );
      let $contents = $contentsPage.content;
      if (user && !user.avatar) {
        user.avatar = "statics/assets/images/avartar.png";
      }

      return (
        <>
          <div id="top">
            <div className="center-box header-top">
              <span class="f-left top-left">
                <span className="logo-wz">个人中心</span>
                <span className="sitename"><a href={portalUrl + "?token=" + Cookies.get("Ent-Token")} target="_blank">中国安防大数据服务平台</a></span>
                <span className="sitename"><a href={exhSiteUrl + "?token=" + Cookies.get("Ent-Token")} target="_blank">安博云展</a></span>
              </span>
              <button className="btn-logout f-right"
                onClick={(evt) => {
                  this.logout();
                }}
              >
                退出登录
              </button>
            </div>
          </div>
          <div className="center-box">
            <div className="main-content">
              <div class="left">
                <div className="text-c">
                  <img src={imageFileUrl + user.avatar} class="headImg" ref={this.imgRef} />
                </div>
                <div className="text-c font20">{user.userName}
                  <p className="sideName" onClick={(evt) => {
                    this.goAutEnt()
                  }}>
                    <img src="../../assets/images/enterpriseCertification.png" alt="" />
                  </p>
                  {/* 专家申报 */}
                  {/* <p className="sideName" >
                    <a href={expertCenterUrl}>
                      <img src="../../assets/images/expertDeclaration.png" alt="" />
                    </a>
                  </p> */}
                  {/* <p><button class="btn-sign" onClick={(evt => {
                    this.signDay()
                  })}>签到</button></p> */}
                  <hr className="hrCss" />
                </div>
                <ul class="side-menu">

                  {/* <li ><a href={serviceCenterUrl}>企业服务中心</a></li> */}
                  {Pages.map(({ key, name }) => (
                    <li class={classNames("nav", {
                      active: this.activePageKey == key,
                    })}>
                      <a
                        href={`#/${key}`}
                        class={classNames("nav", {
                          active: this.activePageKey == key,
                        })}
                      >
                        {name}
                      </a>
                    </li>
                  ))}
                  {/* <li><a href={expertCenterUrl}>专家申报</a></li>
                  <li class='entLi' style={`display:${this.flag == true ? 'block' : 'none'}`}><a href={serviceCenterUrl}>企业服务中心</a></li> */}
                </ul>
              </div>
              <div class="right">
                {$contents}
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="center-box">
              中国国际社会公共安全产品博览会 京ICP备07503664号-2
            </div>
          </div>
        </>
      );
    }
  }
);
export default <App />;
