const { h, define, Component, createRef } = omii;
import "../../es-lib/omii/latest/ui/omii-ui.js"
import css from "../css/home.scss";
import Dialog from "./dialog/dialog"
import security from "../security";
import { getToken } from "../token";
import { constant } from '../lib/constant'
import { systemController, systemUpoadController } from "../apis/index";

define("oi-dialog-box", Dialog)

let images = [];
let phoneValidator = "^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\\d{8}$";
// let emailValidator = "^([a-zA-Z]|[0-9])(\\w|\\-)+@([a-zA-Z0-9_-])+\\.([a-zA-Z]{2,4})$";
let emailValidator = "^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$";
// let emailValidator = "^([a-zA-Z]|[0-9])(\\w|\\-)+@[a-zA-Z0-9]+\\.([a-zA-Z]{2,4})$";

export default class extends Component {
    static css = css;
    tip = "获取验证码";
    disBtn = false
    text = ''
    userInfo = {
        newEmail: ''
    };
    contentUserInfo = {}
    // get userInfo() {
    //     return this.settings.user
    // }

    imageCss = `
    .viewer .image,.selector{
        box-shadow:none !important;
        border:#f4f4f4 solid 1px !important;
        border-radius:100%;
        cursor:pointer;
    }
    .selector{
        box-shadow:none !important;
        border-radius:100%!important;
    }
    .selector::after{
        line-height:100%;
        color:#f4f4f4!important;
    }
    `

    emailSetting = {
        width: "660px",
        height: "487px",
        show: false,
        title: "更换邮箱"
    }
    phoneSetting = {
        width: "660px",
        height: "600px",
        show: false,
        title: "更换手机号码"
    }
    unbindSetting = {
        width: "160px",
        height: "140px",
        show: false,
        title: "解除绑定"
    }
    updateRef = createRef();
    fileRef = createRef();
    uploadPhoto() {
        this.fileRef.current.click();
        var fileInput = this.fileRef.current;
        fileInput.addEventListener("change", function () {
            console.log(fileInput.files, 'fileInput');
            if (fileInput.files[0].size > 1024 * 100 * 3) {
                alert('图片不得大于300K')
            } else {
                let fileObj = new FormData();
                fileObj.append("avatarfile", fileInput.files[0]);
                fetch('/prod-api/system/user/profile/avatar', {
                    method: 'POST',
                    body: fileObj,
                    headers: {
                        Authorization: `Bearer ${getToken()}`
                    }
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data.code == 200) {
                            alert("上传成功", function () {
                                location.reload();
                            })
                        } else {
                            alert(data.msg)
                        }
                    })
                    .catch(error => {
                        console.error(error)
                    })
            }
        })

    }
    updateProfile() {
        let requiredArr = this.updateRef.current.querySelectorAll("input.required");
        let flag = true;
        console.log(requiredArr)
        for (var i = 0; i < requiredArr.length; i++) {
            let item = requiredArr[i];
            console.log("name:", item.name)
            if (!this.userInfo[item.name]) {
                flag = false;
                let msgNode = item.parentNode.querySelector("span.errorTip");
                if (!msgNode) {
                    let span = document.createElement("span");
                    span.className = "errorTip";
                    span.innerText = item.dataset.msg;
                    item.parentNode.appendChild(span);
                }
            } else {
                let msgNode = item.parentNode.querySelector("span.errorTip");
                if (msgNode) {
                    item.parentNode.removeChild(msgNode)
                }
            }
        }
        if (flag) {
            // console.log(this.userInfo)
            //to update
            fetch('/prod-api/system/user/profile', {
                method: 'put',
                body: JSON.stringify(this.userInfo),
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                    "Content-Type": "application/json;charset=UTF-8"
                }
            })
                .then(response => response.json())
                .then(data => {
                    if (data.code == 200) {
                        if (this.userInfo.oldPassword && this.userInfo.newPassword) {
                            if (!this.userInfo.confirmPassword) {
                                alert("请输入确认密码");
                                return false;
                            } else {
                                if (this.userInfo.newPassword != this.userInfo.confirmPassword) {
                                    alert("新密码两次输入不一致");
                                    return false;
                                }
                            }

                            fetch(`/prod-api/system/user/profile/updatePwd?oldPassword=${this.userInfo.oldPassword}&newPassword=${this.userInfo.newPassword}`, {
                                method: 'put',
                                body: JSON.stringify(this.userInfo),
                                headers: {
                                    Authorization: `Bearer ${getToken()}`,
                                    "Content-Type": "application/json;charset=UTF-8"
                                }
                            })
                                .then(response => response.json())
                                .then(data => {
                                    if (data.code == 200) {
                                        alert("操作成功")
                                    } else {
                                        alert(data.msg)
                                    }
                                })
                                .catch(error => {
                                    console.error(error)
                                })
                        } else {
                            alert("操作成功")
                        }
                    } else {
                        alert(data.msg)
                    }
                })
                .catch(error => {
                    console.error(error)
                })
        }
    }
    showEmailDialog() {
        this.userInfo.newEmail = ""
        console.log(this.userInfo)
        this.emailSetting.show = true;
        this.update();
    }
    closeEmailDialog() {
        showEmail = false;
        this.update();
    }
    showPhoneDialog() {
        this.phoneSetting.show = true;
        this.update();
    }
    closePhoneDialog() {
        showPhone = false;
        this.update();
    }
    showUnbindDialog(type) {
        this.unbindSetting.show = true;
        this.type = type
        this.text = type == 0 ? '微信' : type == 1 ? 'QQ' : '微博'
        this.update();
    }
    closeUnbindDialog() {
        this.unbindSetting.show = false;
        this.update();
    }

    /*function validateEmail(email, regex) {
        return regex.test(email)
    }*/

    //
    /*handleInputChange(event) {
        const target = event.target;
        const value = target.value
        const regex = new RegExp(target.dataset.reg)
        const isValid = validateEmail(value,regex)
        console.log("isValid:" + isValid)
        //this.update();
    }*/

    updateEmail() {
        let flag = true;
        // for (var i = 0; i < this.$$("emailrules .required").length; i++) {
        for (var i = 0; i < this.$$(".required").length; i++) {
            let item = this.$$(".required")[i];
            let name = item.getAttribute("name");
            let nameArr = name.split(".");
            if (nameArr[0] === "newPhonenumber") {
                continue
            }
            console.log("nameArr:", nameArr)
            let currentValue = this.userInfo[nameArr[0]];
            // let currentValue = this.userInfo[name];
            if (nameArr.length > 1) {
                currentValue = this.userInfo[nameArr[0]][nameArr[1]];
            }
            // if(name == 'newPhonenumber'){
            console.log("currentValue:", currentValue)
            if (!currentValue) {
                flag = false;
                let msgNode = item.parentNode.querySelector("span.errorTip");
                if (!msgNode) {
                    let span = document.createElement("span");
                    span.className = "errorTip";
                    span.innerText = item.dataset.msg;
                    item.parentNode.appendChild(span);
                }
            } else {
                console.log(flag);
                let msgNode = item.parentNode.querySelector("span.errorTip");
                let reg = item.dataset.reg;
                //   console.log(reg);
                if (reg) {
                    reg = new RegExp(reg);
                    if (!reg.test(currentValue)) {
                        flag = false;
                        if (msgNode) {
                            msgNode.innerText = item.dataset.tip;
                        } else {
                            let span = document.createElement("span");
                            span.className = "errorTip";
                            span.innerText = item.dataset.tip;
                            item.parentNode.appendChild(span);
                        }
                    } else {
                        if (msgNode) {
                            item.parentNode.removeChild(msgNode)
                        }
                    }
                } else {
                    console.log(123)
                    if (msgNode) {
                        item.parentNode.removeChild(msgNode)
                    }
                }
            }
        }
        console.log(flag);
        if (flag) {
            let info = JSON.parse(JSON.stringify(this.userInfo))
            info.email = this.userInfo.newEmail

            fetch('/prod-api/system/user/profile/updateEmail', {
                method: 'put',
                body: JSON.stringify(info),
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                    "Content-Type": "application/json;charset=UTF-8"
                }
            })
                .then(response => response.json())
                .then(data => {
                    console.log(data);
                    if (data.code == 200) {
                        alert("修改成功!")
                        this.userInfo.email = this.userInfo.newEmail
                        this.userInfo.newEmail = ""
                        this.emailSetting.show = false;
                        this.update();

                    } else {
                        alert(data.msg);
                    }
                })
            this.update();
        }
    }
    updatePhone() {
        let flag = true;
        for (var i = 0; i < this.$$(".required").length; i++) {
            let item = this.$$(".required")[i];
            let name = item.getAttribute("name");
            if (name) {
                let nameArr = name.split(".");
                if (nameArr[0] === "newEmail") {
                    continue
                }
                let currentValue = this.userInfo[nameArr[0]];
                // let currentValue = this.userInfo[name];
                if (nameArr.length > 1) {
                    currentValue = this.userInfo[nameArr[0]][nameArr[1]];
                }
                // if(name == 'newPhonenumber'){
                if (!currentValue) {
                    flag = false;
                    let msgNode = item.parentNode.querySelector("span.errorTip");
                    if (!msgNode) {
                        let span = document.createElement("span");
                        span.className = "errorTip";
                        span.innerText = item.dataset.msg;
                        item.parentNode.appendChild(span);
                    }
                } else {
                    console.log(flag);
                    let msgNode = item.parentNode.querySelector("span.errorTip");
                    let reg = item.dataset.reg;
                    //   console.log(reg);
                    if (reg) {
                        reg = new RegExp(reg);
                        console.log("reg:", reg.test(currentValue))
                        if (!reg.test(currentValue)) {
                            console.log("msgNode", msgNode)
                            flag = false;
                            if (msgNode) {
                                msgNode.innerText = item.dataset.tip;
                            } else {
                                let span = document.createElement("span");
                                span.className = "errorTip";
                                span.innerText = item.dataset.tip;
                                item.parentNode.appendChild(span);
                            }
                        } else {
                            if (msgNode) {
                                item.parentNode.removeChild(msgNode)
                            }
                        }
                    } else {
                        console.log(123)
                        if (msgNode) {
                            item.parentNode.removeChild(msgNode)
                        }
                    }
                }
            }
        }
        if (flag) {
            let info = {};
            info.phonenumber = this.userInfo.newPhonenumber;
            info.verifyCode = this.userInfo.code;
            fetch('/prod-api/system/user/profile/updatePhone', {
                method: 'put',
                body: JSON.stringify(info),
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                    "Content-Type": "application/json;charset=UTF-8"
                }
            })
                .then(response => response.json())
                .then(data => {
                    if (data.code == 200) {
                        alert("修改成功!");
                        this.userInfo.phonenumber = this.userInfo.newPhonenumber;

                        this.userInfo.newPhonenumber = ""
                        this.phoneSetting.show = false;
                        this.update();

                    } else {
                        alert(data.msg);
                    }
                })
            this.update();
        }
    }
    getImage() {
    }
    //获取验证码
    getVerify() {
        if (this.userInfo.phonenumber == this.userInfo.oldPhonenumber) {
            alert('新号码不能与原号码相同')
        } else {
            let num = 60
            //设置循环定时器
            var myreg = /^[1][3,5,4,7,8][0-9]{9}$/
            if (myreg.test(this.userInfo.newPhonenumber)) {
                this.disBtn = true
                let that = this
                let timer = setInterval(function () {
                    num--
                    if (num <= 0) {
                        //清除循环执行定时器
                        clearInterval(timer);
                        //还原
                        num = 60;
                        that.disBtn = false
                        that.tip = "获取验证码";
                    } else {
                        that.tip = num + '秒后可重新获取';
                    }
                    that.update();
                }, 1000)
                fetch('/prod-api/manage/open/smscode/Send?tel=' + this.userInfo.newPhonenumber, {
                    method: 'get',
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                        "Content-Type": "application/json;charset=UTF-8"
                    }
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data.code == 200) {
                        } else {
                            alert(data.msg)
                        }
                    })
            } else {
                alert('输入正确手机号')
            }
            this.update();
        }
    }
    // getSMSCode(ev) {
    //     fetch('/prod-api/manage/open/smscode/Send?tel=' + this.userInfo.newPhonenumber, {
    //         method: 'get',
    //         headers: {
    //             Authorization: `Bearer ${getToken()}`,
    //             "Content-Type": "application/json;charset=UTF-8"
    //         }
    //     })
    //         .then(response => response.json())
    //         .then(data => {
    //             if (data.code == 200) {
    //                 alert("修改成功!");
    //             } else {
    //                 alert(data.msg);
    //             }
    //         })
    //     this.update();
    // }
    //第三方登录
    handleOtherLogin() {
        var paramArr = location.href.substr(location.href.indexOf("?") + 1).split("&");
        let obj = {};
        for (let i = 0; i < paramArr.length; i++) {
            let name = paramArr[i].substr(0, paramArr[i].indexOf("="));
            let value = paramArr[i].substr(paramArr[i].indexOf("=") + 1);
            if (name) {
                obj[name] = value;
            }
        }
        if (obj.code && obj.state == 'weixin') {//微信登录
            console.log(obj.code)
            let url = '/prod-api/manage/wx/weiXinLogin?appid=' + constant.weixinId + '&secret=' + constant.weixinSecret + '&code=' + obj.code
            //&grant_type=authorization_code'
            fetch(url, {
                method: 'get'
            }).then(response => response.json())
                .then(res => {
                    console.log(res)
                    this.connentInfo(0, res.unionid)
                })
        } else if (obj.code && obj.state == 1213456) {//qq登录
            let url = "/qq/oauth2.0/token?grant_type=" + obj.code + "&client_id=" + constant.QQappid + "&client_secret=" + constant.QQkey + "&redirect_uri=" + location.href + "&code=" + obj.code
            fetch(url, {
                method: 'get'
            }).then(response => response.json())
                .then(res => {
                    console.log(res)
                    localStorage.setItem('qq_access_token', obj.access_token)
                    localStorage.setItem('qq_access_time', new Date())
                    let url1 = "/qq/oauth2.0/me?access_token=" + obj.access_token
                    fetch(url1, {
                        method: 'get'
                    }).then(r => {
                        console.log(r)
                        let url2 = "/qq/user/get_user_info?access_token=" + obj.access_token + "&oauth_consumer_key=" + contant.QQappid + "&openid=" + r.openid
                        fetch(url2, {
                            method: 'get'
                        }).then(e => {
                            console.log(e)//获取到用户信息
                        })
                    })
                })
        } else if (obj.code) {//微博登录
            console.log(obj.code)
            let url = '/prod-api/manage/wx/weiBoLogin'
            fetch(url, {
                headers: {
                    "Content-type": "application/json"
                },
                method: 'post',
                body: JSON.stringify({
                    client_id: constant.weiboKey,
                    client_secret: constant.weiboSecret,
                    //grant_type:'authorization_code',
                    redirect_uri: 'https://personalcenter.afdata.org.cn',
                    code: obj.code
                })
            }).then(response => response.json())
                .then(res => {
                    console.log(res)
                    localStorage.setItem('weibo_uid', res.uid)
                    this.connentInfo(2, res.uid)
                })
        }
    }
    async install() {
        let userObj = await security.getUserInfo();
        if (userObj.code != 200) {
            security.logout();
        } else {
            this.userInfo = userObj.user;
            // console.log(this.userInfo)
            //this.connentUserInfo =userObj.wxuser
            this.connentUserInfo = {
                weixinId: userObj.user.weixinId,
                qqId: userObj.user.qqId,
                weiboId: userObj.user.weiboId,
                userId: userObj.user.userId,
                userName: userObj.user.userName,
            }
        }
        this.handleOtherLogin()

    }
    handleBind(type) {
        console.log(type)
        switch (type) {
            case 0: console.log('weixin'); window.location.href = " https://open.weixin.qq.com/connect/qrconnect?appid=" + constant.weixinId + "&redirect_uri=" + encodeURIComponent('https://personalcenter.afdata.org.cn') + "&response_type=code&scope=snsapi_login&state=weixin#wechat_redirect"; break;
            case 1: console.log('qq'); window.location.href = "https://graph.qq.com/oauth2.0/authorize?response_type=code&client_id=" + constant.QQappid + "&redirect_uri=https://personalcenter.afdata.org.cn&state=1213456"; break;
            case 2: console.log('weibo'); window.location.href = "https://api.weibo.com/oauth2/authorize?client_id=" + constant.weiboKey + "&response_type=code&redirect_uri=https://personalcenter.afdata.org.cn"; break;
        }
    }
    handleUnBind(type) {
        let url = ''
        let idType = ''
        let userId = this.connentUserInfo.userId
        let userName = this.connentUserInfo.userName
        switch (type) {
            case 0: url = 'unbindWeixin'; idType = 'weixinId'; break;
            case 1: url = 'unbindQQ'; idType = 'qqId'; break;
            case 2: url = 'unbindWeibo'; idType = 'weiboId'; break;
        }
        fetch('/prod-api/system/user/' + url, {
            headers: {
                Authorization: `Bearer ${getToken()}`,
                "Content-type": "application/json",
            },
            method: 'post',
            body: JSON.stringify({
                userId: userId,
                userName: userName,
            })
        }).then(response => response.json())
            .then(res => {
                if (res.code == 200) {
                    this.closeUnbindDialog()
                    this.connentUserInfo[idType] = ''
                } else {
                    alert(data.msg);
                }
            })
    }
    connentInfo(type, uid) {
        let userId = this.connentUserInfo.userId
        let userName = this.connentUserInfo.userName
        let url = ''
        let idType = ''
        let obj = {}
        switch (type) {
            case 0: url = 'bindWeixin'; obj = { weixinId: uid }; idType = 'weixinId'; break;
            case 1: url = 'bindQQ'; obj = { qqId: uid }; idType = 'qqId'; break;
            case 2: url = 'bindWeibo'; obj = { weiboId: uid }; idType = 'weiboId'; break;
        }
        fetch('/prod-api/system/user/' + url, {
            headers: {
                Authorization: `Bearer ${getToken()}`,
                "Content-type": "application/json",
            },
            method: 'post',
            body: JSON.stringify(Object.assign({
                userId: userId,
                userName: userName,
            }, obj))
        }).then(response => response.json())
            .then(res => {
                console.log(res)
                if (res.code == 200) {
                    this.connentUserInfo[idType] = uid
                    this.update()
                } else {
                    alert(res)
                }
            })
    }
    render() {
        return (
            <>
                <div className="profile-box" style="width:80%;margin:0 10% 2rem">
                    <div className="oi-row-full font26">个人资料</div>
                    <div className="oi-row" ref={this.updateRef}>
                        <div className="oi-row-full img-row">
                            <input ref={this.fileRef} type="file" accept="image/*" style="display:none" />
                            <span class="btn-upload" onClick={evt => {
                                this.uploadPhoto()
                            }}>上传头像</span>
                        </div>
                        <div className="oi-row oi-row-full">
                            <div className="oi-col-12">
                                <label className="oi-label" htmlFor="nickName">用户名<i class="red">*</i>：</label>
                                <div className="oi-col-content">
                                    {this.userInfo.userName}
                                </div>
                            </div>
                            <div className="oi-col-12">
                                <label className="oi-label" htmlFor="nickName">性别<i class="red">*</i>：</label>
                                <div className="oi-col-content">
                                    <label className="oi-label horizontal">
                                        <input type="radio" value="1" name="sex" o-model="userInfo.sex"></input>男
                                    </label> <label className="oi-label horizontal">
                                        <input type="radio" value="0" name="sex" o-model="userInfo.sex"></input>女
                                    </label>

                                </div>
                            </div>
                        </div>
                        <div className="oi-row oi-row-full">

                            <div className="oi-col-12">
                                <label className="oi-label" htmlFor="nickName">邮箱地址：</label>
                                <div className="oi-col-content">
                                    <span>{this.userInfo.email}</span><span class="blue12" onClick={(evt) => {
                                        this.showEmailDialog();
                                    }}>去修改</span>
                                </div>
                            </div>
                            <div className="oi-col-12">
                                <label className="oi-label" htmlFor="nickName">手机号码：</label>
                                <div className="oi-col-content">
                                    <span>{this.userInfo.phonenumber}</span><span class="blue12" onClick={(evt) => {
                                        this.showPhoneDialog()
                                    }}>去修改</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="oi-row-full font26 m1t">密码修改</div>
                    <div className="oi-row emailrules oi-col-12">
                        <div className="oi-col-24">
                            <label className="oi-label" htmlFor="nickName">原密码：</label>
                            <div className="oi-col-content">
                                <input type="password" name="oldPassword" o-model="userInfo.oldPassword" />
                            </div>
                        </div>
                        <div className="oi-col-24">
                            <label className="oi-label" htmlFor="nickName">新密码：</label>
                            <div className="oi-col-content">
                                <input type="password" name="newPassword" o-model="userInfo.newPassword" />
                            </div>
                        </div>
                        <div className="oi-col-24">
                            <label className="oi-label" htmlFor="nickName">确认新密码：</label>
                            <div className="oi-col-content">
                                <input type="password" name="newPassword" o-model="userInfo.confirmPassword" />
                            </div>
                        </div>
                    </div>
                    <div className="oi-row-zh" style="display:none">
                        <h3>绑定第三方账号</h3>
                        <ul>
                            <li>
                                {this.connentUserInfo.weixinId ? <img src="../../assets/images/otherLogin/grzx01_active.png" /> : <img src="../../assets/images/otherLogin/grzx01.png" />}
                                {this.connentUserInfo.weixinId ? <span onclick={evt => { this.showUnbindDialog(0) }}>解除绑定</span> : <span onclick={evt => { this.handleBind(0) }}>绑定账号</span>}
                                {this.connentUserInfo.weixinId ? <b>ID：森林小熊</b> : ''}
                            </li>
                            <li>
                                {this.connentUserInfo.qqId ? <img src="../../assets/images/otherLogin/grzx02_active.png" /> : <img src="../../assets/images/otherLogin/grzx02.png" />}
                                {this.connentUserInfo.qqId ? <span onclick={evt => { this.showUnbindDialog(1) }}>解除绑定</span> : <span onclick={evt => { this.handleBind(1) }}>绑定账号</span>}
                                {this.connentUserInfo.qqId ? <b>ID：森林小熊</b> : ''}
                            </li>
                            <li>
                                {this.connentUserInfo.weiboId ? <img src="../../assets/images/otherLogin/grzx03_active.png" /> : <img src="../../assets/images/otherLogin/grzx03.png" />}
                                {this.connentUserInfo.weiboId ? <span onclick={evt => { this.showUnbindDialog(2) }}>解除绑定</span> : <span onclick={evt => { this.handleBind(2) }}>绑定账号</span>}
                                {this.connentUserInfo.weiboId ? <b>ID：森林小熊</b> : ''}
                            </li>
                        </ul>
                    </div>
                    <div className="oi-row text-c" style="margin-top:2rem">
                        <button class="btn-submit" onClick={(evt) => {
                            this.updateProfile();
                        }}>保存</button>
                    </div>
                    <oi-dialog-box settings={this.phoneSetting}>
                        <div className="oi-row phonerules">
                            <div className="oi-col-24">
                                <label className="oi-label" htmlFor="nickName">原手机号码</label>
                                <div className="oi-col-content m3b">
                                    <input type="text" disabled name="city" value={this.userInfo.phonenumber} o-model="userInfo.phonenumber" />
                                </div>
                            </div>
                            <div className="oi-col-24">
                                <label className="oi-label" htmlFor="nickName">新手机号码</label>
                                <div className="oi-col-content m3b">
                                    <input type="text" className="form-input required" value={this.userInfo.newPhonenumber} name="newPhonenumber" o-model="userInfo.newPhonenumber"
                                        data-msg="请输入新手机号" data-reg={phoneValidator} data-tip="手机号格式不正确" />
                                    <span disabled={this.disBtn} class="smsCode" onClick={evt => {
                                        this.getVerify();
                                    }}>{this.tip}</span>
                                </div>
                            </div>
                            <div className="oi-col-24">
                                <label className="oi-label" htmlFor="nickName">短信验证码</label>
                                <div className="oi-col-content m3b">
                                    <input type="text" name="city" o-model="userInfo.code" />
                                </div>
                            </div>
                        </div>
                        <div slot="footer" className="text-c">
                            <button className="btn-submit m3t" onClick={evt => {
                                this.updatePhone()
                            }}>确 定</button>
                        </div>
                    </oi-dialog-box>
                    <oi-dialog-box settings={this.emailSetting} >
                        <div className="oi-row">
                            <div className="oi-col-24">
                                <label className="oi-label" htmlFor="nickName">原邮箱地址</label>
                                <div className="oi-col-content m3b">
                                    <input type="text" value={this.userInfo.email} name="city" disabled="true" />
                                </div>
                            </div>
                            <div className="oi-col-24">
                                <label className="oi-label" htmlFor="nickName">新邮箱地址</label>
                                <div className="oi-col-content m3b">
                                    <input type="text" name="newEmail" o-model="userInfo.newEmail" value={this.userInfo.newEmail}
                                        onChange={this.handleInputChange} className="form-input required" data-msg="请输入新邮箱"
                                        data-reg={emailValidator} data-tip="邮箱格式不正确" />
                                </div>
                            </div>
                        </div>
                        <div slot="footer" className="text-c">
                            <button className="btn-submit m3t" onClick={evt => {
                                this.updateEmail()
                            }}>确 定</button>
                        </div>
                    </oi-dialog-box>
                    <oi-dialog-box settings={this.unbindSetting} >
                        <div style="text-align:center;font-size:18px">解除{this.text}绑定</div>
                        <div slot="footer" className="text-c">
                            <button className="btn-submit m3t" onClick={evt => {
                                this.handleUnBind(this.type)
                            }}>确 定</button>
                        </div>
                    </oi-dialog-box>
                </div>
            </>
        )
    }

}
